@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Poppins", "Urbanist" sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::-webkit-scrollbar {
        width: 2px;
        margin-left: 3px;
    }
    &::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        width: 9px;
        background: #727272 !important;
        border-radius: 10px;
    }
}

body:has(.dropdown-menu) {
    padding-right: 0px !important;
    .MuiPaper-elevation {
        background: rgba(20, 20, 20, 1) !important;
        font-family: "Urbanist";
    }
    .MuiListItemText-primary {
        color: rgba(153, 153, 153, 1);
    }
    .MuiMenuItem-root {
        gap: 5px;
    }
    .MuiListItemIcon-root {
        justify-content: space-around;
        align-items: center;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 1400px) {
    .custom-container {
        max-width: 1320px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        max-width: 1140px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        max-width: 960px;
    }
}

@media (min-width: 768px) {
    .custom-container {
        max-width: 720px;
    }
}

@media (min-width: 576px) {
    .custom-container {
        max-width: 540px;
    }
}